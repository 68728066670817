import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./contents/Main";
import Notfound from "./contents/Notfound";

function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="*" element={<Notfound />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
