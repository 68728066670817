import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios"; // Import axios
import "../main.css";
import cardimg from "../img/card.jpg";

function Main() {
    const ktpInputRef = useRef(null);
    const tokenInputRef = useRef(null);
    const [ktpNumber, setKtpNumber] = useState("");
    const [tokenNumber, setTokenNumber] = useState("");
    const [tokenValidNumber, setTokenValidNumber] = useState("");
    const [isKtpDisabled, setIsKtpDisabled] = useState(true);
    const [isTokenDisabled, setIsTokenDisabled] = useState(true);
    const [dataPasien, setDataPasien] = useState([]);
    const [status, setStatus] = useState("");

    useEffect(() => {
        if (ktpInputRef.current) {
            ktpInputRef.current.focus();
        }
    }, []);

    const handleKtpChange = (e) => {
        const value = e.target.value;
        setKtpNumber(value);
        setIsKtpDisabled(value.length !== 16);
    };

    const handleTokenChange = (e) => {
        const value = e.target.value;
        setTokenNumber(value);
        setIsTokenDisabled(value.length === 0);
    };

    const handleKtpSubmit = async (e) => {
        e.preventDefault();
        setStatus("loading");

        if (ktpNumber) {
            try {
                const response = await axios.post(
                    "https://bunda-thamrin.com/executive/otp",
                    { NIK: ktpNumber }
                );

                if (response.data.metadata.code === 200) {
                    setStatus("spinner");
                    setTimeout(() => {
                        toast.success("Nomor KTP valid: " + ktpNumber);
                        setStatus("valid");
                        setTimeout(() => {
                            setStatus("token");
                            setTokenValidNumber(response.data.metadata.message);
                        }, 2000);
                    }, 2000);

                    console.log(response.data.metadata);
                } else {
                    setStatus("spinner");
                    setTimeout(() => {
                        toast.error("Nik tidak ditemukan!");
                        setStatus("");
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
                toast.error("Gagal terhubung ke server!");
            }
        }
    };

    const handleTokenSubmit = async (e) => {
        e.preventDefault();

        if (parseInt(tokenNumber) === parseInt(tokenValidNumber)) {
            toast.success("Token valid!");
            setStatus("tokenloading");

            try {
                const response = await axios.post(
                    "https://bunda-thamrin.com/executive/hasilverifikasi",
                    { NIK: ktpNumber }
                );

                if (response.data.metadata.code === 200) {
                    setStatus("spinnerloading");
                    setTimeout(() => {
                        toast.success("Nomor Token valid: " + tokenValidNumber);
                        setStatus("tokenvalid");
                        setTimeout(() => {
                            setStatus("data");
                            setDataPasien(response.data.response);
                        }, 2000);
                    }, 2000);
                } else {
                    toast.error("Token tidak valid!");
                }
            } catch (error) {
                toast.error("Gagal terhubung ke server!");
            }
        } else {
            toast.error("Token tidak valid!");
        }
        console.log(tokenNumber);
        console.log(tokenValidNumber);
    };

    return (
        <>
            <ToastContainer />

            {status === "data" ? (
                <>
                    <div
                        className="label-patient"
                        style={{
                            backgroundImage: `url(${cardimg})`, // Gunakan template literal
                            backgroundSize: "contain", // Sesuaikan ukuran background
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            height: "100vh", // Atur tinggi sesuai kebutuhan
                            width: "100%", // Atur lebar sesuai kebutuhan
                            position: "relative",
                        }}
                    >
                        {/* parent patient */}
                        <div className="parent-patient">
                            <table className="patient-table">
                                <tr>
                                    <td>Nama</td>
                                    <td>:</td>
                                    <td>{dataPasien.nama}</td>
                                </tr>
                                <tr>
                                    <td>Tanggal Lahir</td>
                                    <td>:</td>
                                    <td>{dataPasien.dob}</td>
                                </tr>
                                <tr>
                                    <td>No RM</td>
                                    <td>:</td>
                                    <td>{dataPasien.rm}</td>
                                </tr>
                            </table>
                        </div>

                        {/* qr code */}
                        <div className="qrcode-rm">
                            <img
                                src={`https://barcode.tec-it.com/barcode.ashx?data=${dataPasien.rm}&code=QRCode&translate-esc=on&dpi=110&eclevel=L`}
                                alt={`${dataPasien.rm}`}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div className="container">
                    <div className="card">
                        <h3>
                            {status === "valid" ||
                            status === "token" ||
                            status === "tokenloading" ||
                            status === "spinnerloading" ||
                            status === "tokenvalid"
                                ? "Pengisian Nomor OTP"
                                : "Pengisian Nomor KTP"}
                        </h3>
                        {(status === "token" ||
                            status === "tokenloading" ||
                            status === "spinnerloading" ||
                            status === "tokenvalid") && (
                            <div className="alert">
                                Silakan masukkan nomor token yang telah kami
                                kirimkan ke WhatsApp Anda.
                            </div>
                        )}
                        {status !== "valid" &&
                            status !== "token" &&
                            status !== "tokenloading" &&
                            status !== "spinnerloading" &&
                            status !== "tokenvalid" &&
                            status !== "data" && (
                                <form
                                    autoComplete="off"
                                    onSubmit={handleKtpSubmit}
                                >
                                    <input
                                        ref={ktpInputRef}
                                        type="number" // Changed to text for better handling
                                        placeholder="Masukkan Nomor KTP (16 digit)"
                                        value={ktpNumber}
                                        onChange={handleKtpChange}
                                    />
                                    <button
                                        type="submit"
                                        className="w-100 btn"
                                        disabled={isKtpDisabled}
                                    >
                                        Dapatkan Nomor OTP
                                    </button>
                                </form>
                            )}
                        {(status === "valid" ||
                            status === "token" ||
                            status === "tokenloading" ||
                            status === "spinnerloading" ||
                            status === "tokenvalid") && (
                            <form
                                autoComplete="off"
                                onSubmit={handleTokenSubmit}
                            >
                                <input
                                    ref={tokenInputRef}
                                    type="number" // Changed to text for better handling
                                    placeholder="Masukkan Nomor OTP"
                                    value={tokenNumber}
                                    onChange={handleTokenChange}
                                />
                                <button
                                    type="submit"
                                    className="w-100 btn"
                                    disabled={isTokenDisabled}
                                >
                                    Selanjutnya
                                </button>
                            </form>
                        )}
                    </div>
                </div>
            )}
            {status === "loading" && (
                <div className={`loading-overlay visible`}>
                    Sebentar, sedang mencocokkan data...
                </div>
            )}
            {status === "spinner" && (
                <div className={`loading-overlay visible`}>
                    <div className="spinner"></div>
                </div>
            )}
            {status === "valid" && (
                <div className={`loading-overlay visible`}>Data Valid!</div>
            )}
            {status === "tokenloading" && (
                <div className={`loading-overlay visible`}>
                    Sebentar, sedang mendapatkan data...
                </div>
            )}
            {status === "spinnerloading" && (
                <div className={`loading-overlay visible`}>
                    <div className="spinner"></div>
                </div>
            )}
            {status === "tokenvalid" && (
                <div className={`loading-overlay visible`}>Token Valid!</div>
            )}
        </>
    );
}

export default Main;
